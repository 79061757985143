.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#components-layout-demo-responsive .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

.site-layout-sub-header-background {
    background: #fff;
}

.Header {

}

.Body {
    background: #5a6169;
    padding: 12px 28px;
    display: flex;
    flex-grow: 1;
    color: white;
}

.Footer {

}

h1, h2, h3, h4, h5, h6, span, div {
    color: white !important;
}

.Divider {
    border: 1px solid #c1c1c5;
    margin-bottom: 16px;
}

button {
    color: black;
}

.LogoButton {
    -webkit-transition: -webkit-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
}

.LogoButton:hover {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

/* unvisited link */
a:link {
    color: white;
}

/* visited link */
a:visited {
    color: white;
}

/* mouse over link */
a:hover {
    color: white;
    font-weight: bold;
}

/* selected link */
a:active {
    color: white;
}

.CardImage {
    padding-bottom: 32px;
    max-width: 250px;
    max-height: 250px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.CardImageContainer {
    margin-bottom: 20px;
    position: relative;
    min-height: 250px;
    min-width: 250px;
}

.ImageContainer {
    border: 1px solid #c1c1c5;
    border-radius: 10px;
    margin-bottom: 16px;
    position: relative;
    min-height: 350px;
    min-width: 350px;
}

.image {
    padding-top: 16px;
    padding-bottom: 1px;
    max-width: 350px;
    max-height: 350px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.InfoTable {
    border: 1px solid #c1c1c5;
    border-radius: 10px;
    margin-bottom: 16px;
}

body::-webkit-scrollbar {
    width: 1em;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.StatsTable {
    border: 1px solid #c1c1c5;
    border-radius: 10px;
    margin-bottom: 16px;
}

.PassiveTable {
    border: 1px solid #c1c1c5;
    border-radius: 10px;
    margin-top: 16px;
}

.SyncTable {
    border: 1px solid #c1c1c5;
    border-radius: 10px;
    margin-bottom: 16px;
}

.MovesTable {
    border: 1px solid #c1c1c5;
    border-radius: 10px;
    margin-top: 8px;
}

.MoveBox {
    margin-top: 12px;
    margin-bottom: 16px;
}

.divider {
    border: 0.5px solid #c1c1c5;
    margin-bottom: 16px;
}
